import { css } from "@emotion/react";
import React from "react";
import { FeatureCardStyles } from "../pages/integrations";

interface IBulletPoint {
    name: string;
    enabled: boolean;
}

interface IPricingTableProps {
    price: string;
    name: string;
    bullets: IBulletPoint[];
    signupType: string;
    showPricePerMonth: boolean;
    href: string;
}

const Column = css`
    flex: calc(25% - 16px);
    padding: 16px;
    margin: 8px;
    height: auto;
    
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 992px) {
        flex: 50%;
    }

    ul li:before {
        content: "🗸";
        display: inline-block;
        font-weight: bold;
        width: 20px;
    }

    ul li.enabled:before {
        color: green;
    }

    ul li.disabled:before {
        content: "-";
        color: gray;
    }
`;

const SignUpButton = css`
    background: #2c76bc;
    color: #fff;
    border-radius: 4px;
    padding: 6px 12px;
    display: block;
    margin-top: auto;

    :before {
        display: none;
    }

    :hover, :focus {
        background: #296dae;
        color: #fff;
        text-decoration: none;
    }

    :active {
        background: #2665a0;
        color: #fff;
    }
`;

const PriceAmount = css`
    font-size: 32px;
    font-weight: bold;
    padding-top: 12px;
`;

const CurrencySymbol = css`
    font-size: 16px;
    padding-right: 2px;
    vertical-align: super;
`;

const SmallText = css`
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #969696;
    padding: 10px;
    height: 52px;
`;

const PackageHeader = css`
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 12px;
    margin: 0;
    font-size: 18px;
`;

export const PricingTable: React.FC<IPricingTableProps> = (props) => {
    return (
        <div css={[FeatureCardStyles, Column]}>
            <div css={PackageHeader}>
                {props.name}
            </div>
            <div css={PriceAmount}>
                <span css={CurrencySymbol}>
                    {props.showPricePerMonth &&
                        <React.Fragment>$</React.Fragment>
                    }
                </span>
                {props.price}
                <div css={SmallText}>
                    {props.showPricePerMonth &&
                        <React.Fragment>
                            <div>Per user</div>
                            <div>Per month</div>
                        </React.Fragment>
                    }
                </div>
            </div>

            <div className="custom-separator my-4 mx-auto bg-primary" />

            <ul style={{ textAlign: "left", fontSize: "14px", listStyle: "none", padding: 0 }}>
                {props.bullets.map((item) => (
                    <li className={item.enabled ? "enabled" : "disabled"}>
                        <i className="fa fa-check mr-2 text-primary" />
                        {item.name}
                    </li>
                ))}
            </ul>
            <a href={props.href} css={SignUpButton}>
                {props.signupType}
            </a>
        </div>
    );
};

const FlexColumn = css`
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

export const PricingTableWrapper: React.FC = (props) => {
    return (
        <section css={FlexColumn}>
            {props.children}
        </section>
    );
};