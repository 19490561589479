import React from 'react';
import { Helmet } from 'react-helmet';

import { Footer } from '../components/Footer';
import { inner, outer, TransitionNavigation } from '../components/header/SiteNav';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  SiteMain
} from '../styles/shared';
import { NoImage, PostFull } from '../templates/post';
import { PricingTable, PricingTableWrapper } from "../components/PricingTable";

const Pricing: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>FastApps Pricing</title>
    </Helmet>
    <Wrapper>
      <TransitionNavigation mode="white" background="#2c76bc" />
      <main id="site-main" className="site-main" css={[SiteMain, outer]} style={{ marginTop: 32 }}>
        <div css={inner} style={{ marginTop: 0 }}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PricingTableWrapper>
              <PricingTable
                price="Free"
                name="Free"
                signupType="Get Started"
                href="http://app.fastapps.io/user/signup"
                showPricePerMonth={false}
                bullets={[
                  {
                    name: "All users have edit access",
                    enabled: true
                  },
                  {
                    name: "Single-Sign-On (github, google)",
                    enabled: true
                  },
                  {
                    name: "Versioned releases with easy rollbacks",
                    enabled: true
                  }
                ]}
              />
              <PricingTable
                price="12"
                name="Startup"
                signupType="Start Free Trial"
                href="http://app.fastapps.io/user/signup"
                showPricePerMonth
                bullets={[
                  {
                    name: "Free Plan Plus...",
                    enabled: true
                  },
                  {
                    name: "Granular user permissions",
                    enabled: true
                  },
                  {
                    name: "Salesforce integration (coming soon)",
                    enabled: true
                  },
                  {
                    name: "Audit Logs",
                    enabled: true
                  },
                  {
                    name: "Remove FastApps branding",
                    enabled: true
                  },
                ]}
              />
              <PricingTable
                price="40"
                name="Pro"
                signupType="Start Free Trial"
                href="http://app.fastapps.io/user/signup"
                showPricePerMonth
                bullets={[
                  {
                    name: "Starter Plan Plus...",
                    enabled: true
                  },
                  {
                    name: "Test/Staging versions",
                    enabled: true
                  },
                  {
                    name: "Custom Domain",
                    enabled: true
                  },
                  {
                    name: "Version control with Git",
                    enabled: true
                  }
                ]}
              />
              <PricingTable
                price="Custom"
                name="On Prem"
                signupType="Schedule a Demo"
                href="mailto:sales@fastapps.io?subject=FastApps%20custom%20pricing%20request&body=Give%20us%20a%20short%20description%20about%20your%20company!"
                showPricePerMonth={false}
                bullets={[
                  {
                    name: "Pro Plan Plus...",
                    enabled: true
                  },
                  {
                    name: "Custom high volume discounts",
                    enabled: true
                  },
                  {
                    name: "Deploy on Premises (Optional)",
                    enabled: true
                  },
                  {
                    name: "Custom integrations",
                    enabled: true
                  },
                  {
                    name: "Priority support",
                    enabled: true
                  }
                ]}
              />
            </PricingTableWrapper>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper >
  </IndexLayout >
);

export default Pricing;
